<template>
  <div class="termgenerator">
    <m-frame :routeList="menuList" alive-include="Home" title="详细页询盘" />
  </div>
</template>

<script>
import MFrame from '@/components/frame/Frame.vue';
export default {
  components: {
    'm-frame': MFrame,
  },
  setup() {
    const menuList = [
      {
        path: '',
        title: '操作指南',
      },
    ];

    return {
      menuList,
    };
  },
};
</script>

<style lang="scss"></style>
